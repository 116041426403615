import { useContext, useMemo } from "react";
import { useRecoilState } from "recoil";
import { useIdleTimer } from "react-idle-timer";
import { useAuth0 } from "@divide/ois-react-components";
import securityCodeDialogActiveAtom from "store/securityCodeDialogActive";
import { AppContext } from "parts/App";

const defaultTimeout = 300000;

export default function useIdleSecurityCodeDialog (timeout = defaultTimeout, isDisabled = false) {
    const { isAuthenticated } = useAuth0();
    const { siConfiguration } = useContext(AppContext)
    const [active, setActive] = useRecoilState(securityCodeDialogActiveAtom);

    const disabled = useMemo(() => {
        return !isAuthenticated || siConfiguration?.SecurityCode?.length < 3 || isDisabled;
    }, [isAuthenticated, siConfiguration.SecurityCode, isDisabled]);
    
    const { reset } = useIdleTimer({ 
        timeout, 
        disabled, 
        onIdle: () => setActive(true) 
    });

    const deactivate = () => {
        setActive(false);
        reset();
    }

    return {
        active,
        disabled,
        deactivate
    };
}