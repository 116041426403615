const deDe = {
    global: {
        action: "Aktion",
        address: "Adresse",
        addresses: "Adressen",
        amountOfPoints: "Punktzahl",
        applicationUser: "Anwendungsbenutzer",
        averagePiecesPerPurchase: "Durchschnittliche Stückzahl pro Einkauf",
        averagePurchaseAmount: "Durchschnittlicher Kaufbetrag",
        averagePurchasesPerYear: "Durchschnittliche Einkäufe pro Jahr",
        backToOverview: "Zurück zur Übersicht",
        barcode: "Barcode",
        beginsWith: "Beginnt mit",
        birthdate: "Geburtsdatum",
        boughtProducts: "Gekaufte(s) Produkt(e)",
        branches: "Standorte",
        brand: "Marke",
        brands: "Bemerken",
        categories: "Kategorien",
        category: "Kategorie",
        collection: "Sammlung",
        collections: "Sammlungen",
        color: "Farbe",
        colors: "Färben",
        condition: "Zustand",
        contains: "Enthält",
        customerData: "Kundendaten",
        customerHasNoBoughtProducts: "Dieser Kunde hat noch keine Produkte gekauft.",
        customerNumber: "Kundennummer",
        customerNumberAbbreviated: "Kunden-Nr.",
        customerSince: "Kunde seit",
        date: "Datum",
        defaultAddresses: "Standardadressen",
        departments: "Abteilungen",
        description: "Beschreibung",
        details: "Einzelheiten",
        emailaddress: "E-Mail-Adresse",
        emailAddress: "E-Mail-Adresse",
        endsWith: "Endet mit",
        equals: "Gleich",
        favoriteBrands: "Lieblingsmarke(n)",
        favoriteStore: "Lieblingsshop",
        filters: "Filter",
        friendsOfCompany: (companyName) => `Freunde von ${companyName}`,
        houseNumber: "Hausnummer",
        interests: "Interessen",
        lastName: "Familienname, Nachname",
        latestTransaction: "Letzte Transaktion",
        less: "Weniger",
        more: "Mehr",
        mostCommonBrands: "Die gängigsten Marken",
        name: "Name",
        newsletterSubscription: "Newsletter-Registrierung",
        nextTenResults: "Nächste 10 Ergebnisse",
        no: "Nein",
        noAddress: "Keine Adresse",
        noAddressesFound: "Keine Adresse(n) gefunden.",
        noBrandsFound: "Keine Marken gefunden",
        originalSellingPrice: "Ursprünglicher Verkaufspreis",
        otherAddresses: "Andere Adressen",
        phoneNumber: "Telefonnummer",
        postalCode: "Postleitzahl",
        postalCodeHouseNumber: "Postleitzahl + Hausnummer",
        priceAscending: "Preis aufsteigend",
        priceDescending: "Preis absteigend",
        removeAll: "Alles löschen",
        results: "Ergebnisse",
        rfmGroup: "RFM-Gruppe",
        salutationFemale: "Frau.",
        salutationMale: "Herr.",
        searchBy: "Suchen",
        searchCustomer: "Kundensuche",
        searchCustomers: "Kundensuche",
        searchTerm: "Suchbegriff",
        securityCode: "Sicherheitscode",
        selectedFilters: "Ausgewählte Filter",
        sellDate: "Verkaufsdatum",
        sellDateNewestToOldest: "Verkaufsdatum (neu - alt)",
        sellDateOldestToNewest: "Verkaufsdatum (alt - neu)",
        size: "Größe",
        sortBy: "Sortieren nach",
        targetGroups: "Zielpublikum",
        totalAmount: "Gesamtmenge",
        totalPurchases: "Gesamtkäufe",
        totalRetours: "Gesamtrendite",
        transactions: "Transaktionen",
        transactionsPastTwelveMonths: "Abteilung für Transaktionen 12 Monate",
        unknown: "Unbekannt",
        unlock: "Freischalten",
        view: "Zu betrachten",
        viewXAmountProducts: (amount) => `${amount} Produkt(e) anzeigen`,
        webshop: "Webshop",
        whereBought: "Wo verkauft",
        xAmountAddresses: (amount) => `Adressen (${amount})`,
        xAmountProducts: (amount) => `${amount} Produkt(e)`,
        xCustomersFound: (amount) => `${amount} Kunde(n) gefunden`,
        yes: "Ja"
    },
    messages: {
        error: {},
        info: {
            customerCouldNotBeFound: "Dieser Kunde konnte nicht gefunden werden, klicken Sie auf die Schaltfläche unten, um zur Übersicht zurückzukehren.",
            customerNotFound: "Kunde nicht gefunden"
        },
        loading: {},
        validation: {
            fieldRequired: "Dieses Feld ist erforderlich.",
            invalidPostalCode: "Diese Postleitzahl ist ungültig.",
            securityCodeInvalid: "Der Sicherheitscode ist falsch, bitte versuchen Sie es erneut."
        },
        success: {},
        warning: {}
    }
};

export default deDe;
