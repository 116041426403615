const frFr = {
    global: {
        action: "Action",
        address: "Adresse",
        addresses: "Adresses",
        amountOfPoints: "Score",
        applicationUser: "Utilisateur de l'application",
        averagePiecesPerPurchase: "Moyenne de pièces par achat",
        averagePurchaseAmount: "Montant moyen des achats",
        averagePurchasesPerYear: "Achats moyens par an",
        backToOverview: "Retour à l'aperçu",
        barcode: "Code à barre",
        beginsWith: "Commence avec",
        birthdate: "Date de naissance",
        boughtProducts: "Produit(s) acheté(s)",
        branches: "Emplacements",
        brand: "Marque",
        brands: "Marques",
        categories: "Catégories",
        category: "Catégorie",
        collection: "Collection",
        collections: "Collections",
        color: "Couleur",
        colors: "Colorer",
        condition: "Condition",
        contains: "Contient",
        customerData: "Données client",
        customerHasNoBoughtProducts: "Ce client n'a encore acheté aucun produit.",
        customerNumber: "Numéro client",
        customerNumberAbbreviated: "N° client.",
        customerSince: "Client depuis",
        date: "Date",
        defaultAddresses: "Adresses par défaut",
        departments: "Départements",
        description: "Description",
        details: "Détails",
        emailaddress: "Adresse e-mail",
        emailAddress: "Adresse e-mail",
        endsWith: "Se termine par",
        equals: "Égal à",
        favoriteBrands: "Marque(s) préférée(s)",
        favoriteStore: "Magasin préféré",
        filters: "Filtres",
        friendsOfCompany: (companyName) => `Amis de ${companyName}`,
        houseNumber: "Numéro de maison",
        interests: "Intérêts",
        lastName: "Nom de famille",
        latestTransaction: "Dernière transaction",
        less: "Moins",
        more: "Plus",
        mostCommonBrands: "marques les plus courantes",
        name: "Nom",
        newsletterSubscription: "Inscription à la newsletter",
        nextTenResults: "10 prochains résultats",
        no: "Non",
        noAddress: "Pas d'adresse",
        noAddressesFound: "Aucune adresse(s) trouvée(s).",
        noBrandsFound: "Aucune marque trouvée",
        originalSellingPrice: "Prix de vente d'origine",
        otherAddresses: "Autres adresses",
        phoneNumber: "Numéro de téléphone",
        postalCode: "Code postal",
        postalCodeHouseNumber: "Code postal + numéro de maison",
        priceAscending: "Prix croissant",
        priceDescending: "Prix décroissant",
        removeAll: "Tout effacer",
        results: "Résultats",
        rfmGroup: "Groupe RFM",
        salutationFemale: "Mme.",
        salutationMale: "M.",
        searchBy: "Recherche",
        searchCustomer: "Recherche de clients",
        searchCustomers: "Recherche de clients",
        searchTerm: "Terme de recherche",
        securityCode: "Code de sécurité",
        selectedFilters: "Filtre(s) sélectionné(s)",
        sellDate: "Date de vente",
        sellDateNewestToOldest: "Date de vente (nouveau - ancien)",
        sellDateOldestToNewest: "Date de vente (ancien - nouveau)",
        size: "Copain",
        sortBy: "Trier sur",
        targetGroups: "Publics cibles",
        totalAmount: "Montant total",
        totalPurchases: "Total des achats",
        totalRetours: "Retours totaux",
        transactions: "Transactions",
        transactionsPastTwelveMonths: "Département des transactions. 12 mois",
        unknown: "inconnu",
        unlock: "Déverrouiller",
        view: "Regarder",
        viewXAmountProducts: (amount) => `Voir ${amount} produit(s).`,
        webshop: "Boutique en ligne",
        whereBought: "Où vendu",
        xAmountAddresses: (amount) => `Adresses (${amount})`,
        xAmountProducts: (amount) => `${amount} produit(s)`,
        xCustomersFound: (amount) => `${amount} client(s) trouvé(s)`,
        yes: "Oui"
    },
    messages: {
        error: {},
        info: {
            customerCouldNotBeFound: "Ce client est introuvable, cliquez sur le bouton ci-dessous pour revenir à l'aperçu.",
            customerNotFound: "Client introuvable"
        },
        loading: {},
        validation: {
            fieldRequired: "Ce champ est obligatoire.",
            invalidPostalCode: "Ce code postal n'est pas valide.",
            securityCodeInvalid: "Le code de sécurité est incorrect, veuillez réessayer."
        },
        success: {},
        warning: {}
    }
};

export default frFr;
