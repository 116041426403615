const enGb = {
    global: {
        action: "Action",
        address: "Address",
        addresses: "Addresses",
        amountOfPoints: "Amount of points",
        applicationUser: "Application user",
        averagePiecesPerPurchase: "Average pieces per purchase",
        averagePurchaseAmount: "Average purchase amount",
        averagePurchasesPerYear: "Average purchases per year",
        backToOverview: "Back to overview",
        barcode: "Barcode",
        beginsWith: "Begins with",
        birthdate: "Date of birth",
        boughtProducts: "Purchased product(s)",
        branches: "Branches",
        brand: "Brand",
        brands: "Brands",
        categories: "Categories",
        category: "Category",
        collection: "Collection",
        collections: "Collections",
        color: "Color",
        colors: "Colors",
        condition: "Condition",
        contains: "Contains",
        customerData: "Customer data",
        customerHasNoBoughtProducts: "This customer has not purchased any products yet.",
        customerNumber: "Customer number",
        customerNumberAbbreviated: "Customer no.",
        customerSince: "Customer since",
        date: "Date",
        defaultAddresses: "Default addresses",
        departments: "Departments",
        description: "Description",
        details: "Details",
        emailaddress: "E-mail address",
        emailAddress: "E-mail address",
        endsWith: "Ends with",
        equals: "Equals",
        favoriteBrands: "Favorite brand(s)",
        favoriteStore: "Favorite store",
        filters: "Filters",
        friendsOfCompany: (companyName) => `Friends of ${companyName}`,
        houseNumber: "House number",
        interests: "Interests",
        lastName: "Last name",
        latestTransaction: "Last transaction",
        less: "Less",
        more: "More",
        mostCommonBrands: "Most common brands",
        name: "Name",
        newsletterSubscription: "Newsletter subscription",
        nextTenResults: "Next 10 results",
        no: "No",
        noAddress: "No address",
        noAddressesFound: "No address(es) found.",
        noBrandsFound: "No brands found",
        originalSellingPrice: "Original selling price",
        otherAddresses: "Other addresses",
        phoneNumber: "Phone number",
        postalCode: "Postal Code",
        postalCodeHouseNumber: "Postalcode + House Number",
        priceAscending: "Price ascending",
        priceDescending: "Price descending",
        removeAll: "Clear all",
        results: "Results",
        rfmGroup: "RFM group",
        salutationFemale: "Mrs.",
        salutationMale: "Mr.",
        searchBy: "Search",
        searchCustomer: "Customer Search",
        searchCustomers: "Customer Search",
        searchTerm: "Search term",
        securityCode: "Security Code",
        selectedFilters: "Selected filter(s)",
        sellDate: "Sale date",
        sellDateNewestToOldest: "Sale date (newest - oldest)",
        sellDateOldestToNewest: "Sale date (oldest - newest)",
        size: "Size",
        sortBy: "Sort on",
        targetGroups: "Target audencies",
        totalAmount: "Total amount",
        totalPurchases: "Total purchases",
        totalRetours: "Total returns",
        transactions: "Transactions",
        transactionsPastTwelveMonths: "Transactions past 12 months",
        unknown: "Unknown",
        unlock: "Unlock",
        view: "View",
        viewXAmountProducts: (amount) => `${amount} view product(s)`,
        webshop: "Webshop",
        whereBought: "Where sold",
        xAmountAddresses: (amount) => `Addresses (${amount})`,
        xAmountProducts: (amount) => `${amount} product(s)`,
        xCustomersFound: (amount) => `${amount} Customer(s) found`,
        yes: "Yes"
    },
    messages: {
        error: {},
        info: {
            customerCouldNotBeFound: "This customer could not be found, click on the button below to return to the overview.",
            customerNotFound: "Customer not found"
        },
        loading: {},
        validation: {
            fieldRequired: "This field is required.",
            invalidPostalCode: "This zip code is invalid.",
            securityCodeInvalid: "The security code is incorrect, please try again."
        },
        success: {},
        warning: {}
    }
};

export default enGb;
