import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    date: "si_date",
    version: "si_version",
    cdnDomain: "si_cdn-domain",
    productsViewMode: "si_products-view-mode",
    productsSort: "si_products-sort",
    addToHomeScreenMessageIsClosed: "si_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "si_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "si_has-used-home-screen-app",
    updateAvailable: "si_update_available",
    ...sharedStorageKeys
};

export default storageKeys;