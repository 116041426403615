import { useContext, useMemo, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Button, Dialog, Loader } from "@divide/retailsuite-react-components";
import useIdleSecurityCodeDialog from "hooks/general/useIdleSecurityCodeDialog";
import { AppContext } from "parts/App";
import styles from "./SecurityCodeDialog.module.scss";

export function SecurityCodeDialog() {
    const { siConfiguration, translation } = useContext(AppContext);
    const { active, disabled, deactivate } = useIdleSecurityCodeDialog();

    const isLoading = useMemo(() => {
        return !siConfiguration?.hasOwnProperty("SecurityCode") ?? true;
    }, [siConfiguration]);

    const characters = useMemo(() => {
        const securityCode = siConfiguration.SecurityCode;

        if (securityCode) {
            return (securityCode?.length > 6 ? securityCode.slice(0, 6) : securityCode).split("");
        } else {
            return ["0", "0", "0", "0"];
        }
    }, [siConfiguration.SecurityCode]);

    const [securityCodeValue, setSecurityCodeValue] = useState();

    useEffect(() => {
        setSecurityCodeValue(characters.map(() => ""));
    }, [characters]);

    const [form, setForm] = useState();
    const formRef = useCallback((node) => setForm(node));

    const handleChange = (newValue, inputIndex) => {
        let newSecurityCodeValue = [...securityCodeValue];
        newSecurityCodeValue[inputIndex] = [...newValue][0];

        setSecurityCodeValue(newSecurityCodeValue);
        focusNextInput(inputIndex, newValue.length ? "next" : "prev");
    };

    const handleKeyDown = (e, inputIndex) => {
        const value = e.currentTarget.value;

        if (e.key === "Backspace" && value.length === 0) {
            focusNextInput(inputIndex, "prev");
        } else if (["ArrowLeft", "ArrowRight"].includes(e.key)) {
            e.preventDefault();

            if (e.key === "ArrowLeft") {
                focusNextInput(inputIndex, "prev");
            } else {
                focusNextInput(inputIndex, "next");
            }
        }
    };

    const focusNextInput = (inputIndex, direction) => {
        const selector = `[data-index="${direction === "next" ? inputIndex + 1 : inputIndex - 1}"]`;
        const nextInput = form?.querySelector(selector);

        if (nextInput) {
            nextInput.focus();
            nextInput.setSelectionRange(1, 1);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (securityCodeValue?.every((value) => !!value)) {
            const securityCode = characters.join("");
            const givenSecurityCode = securityCodeValue.join("");

            if (securityCode === givenSecurityCode) {
                deactivate();
            } else {
                let firstInput = form?.querySelector("[data-index='0']");

                if (firstInput) {
                    firstInput.focus();
                }

                toast.error(translation("messages.validation.securityCodeInvalid"));
            }

            let newSecurityCodeValue = securityCodeValue.map(() => "");
            setSecurityCodeValue(newSecurityCodeValue);
        }
    };

    const width = useMemo(() => {
        let value = "small";

        if (characters.length === 5) {
            value = "medium";
        } else if (characters.length === 6) {
            value = "large";
        }

        return value;
    }, [characters]);

    return !disabled ? (
        <Dialog
            open={active}
            onClose={deactivate}
            showCloseIcon={false}
            blockScroll={true}
            focusTrapped={true}
            closeOnOverlayClick={false}
            variant="popup"
            closeOnEsc={false}
            classNames={{
                overlay: styles["overlay"],
                container: styles["container"],
                modal: `${styles["modal"]} ${styles[width]}`,
            }}
        >
            <Dialog.Content>
                <h1 className={styles["title"]}>{translation("global.securityCode")}</h1>

                {isLoading ? (
                    <Loader />
                ) : (
                    <form ref={formRef} className={styles["form"]} onSubmit={handleSubmit}>
                        {securityCodeValue && (
                            <div className={styles["fields-wrapper"]}>
                                {characters.map((_, index) => (
                                    <div
                                        key={`security-code-input-${index}`}
                                        className={`${styles["field"]} ${securityCodeValue[index]?.length > 0 ? styles["has-value"] : ""}`}
                                    >
                                        <input
                                            value={securityCodeValue[index]}
                                            type="password"
                                            data-index={index}
                                            onChange={(e) => handleChange(e.currentTarget.value, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        <Button size="large" type="submit">
                            {translation("global.unlock")}
                        </Button>
                    </form>
                )}
            </Dialog.Content>
        </Dialog>
    ) : null;
}
