const nlNl = {
    global: {
        action: "Actie",
        address: "Adres",
        addresses: "Adressen",
        amountOfPoints: "Aantal punten",
        applicationUser: "Applicatie gebruiker",
        averagePiecesPerPurchase: "Gemiddeld stuks per aankoop",
        averagePurchaseAmount: "Gemiddeld aankoopbedrag",
        averagePurchasesPerYear: "Gemiddeld aankopen per jaar",
        backToOverview: "Terug naar overzicht",
        barcode: "Barcode",
        beginsWith: "Begint met",
        birthdate: "Geboortedatum",
        boughtProducts: "Gekochte product(en)",
        branches: "Vestigingen",
        brand: "Merk",
        brands: "Merken",
        categories: "Categorieën",
        category: "Categorie",
        collection: "Collectie",
        collections: "Collecties",
        color: "Kleur",
        colors: "Kleuren",
        condition: "Conditie",
        contains: "Bevat",
        customerData: "Klantgegevens",
        customerHasNoBoughtProducts: "Deze klant heeft nog geen producten gekocht.",
        customerNumber: "Klantnummer",
        customerNumberAbbreviated: "Klantnr.",
        customerSince: "Klant sinds",
        date: "Datum",
        defaultAddresses: "Standaard adressen",
        departments: "Afdelingen",
        description: "Beschrijving",
        details: "Details",
        emailaddress: "E-mailadres",
        emailAddress: "E-mailadres",
        endsWith: "Eindigt met",
        equals: "Gelijk aan",
        favoriteBrands: "Favoriete merk(en)",
        favoriteStore: "Favoriete winkel",
        filters: "Filters",
        friendsOfCompany: (companyName) => `Vrienden van ${companyName}`,
        houseNumber: "Huisnummer",
        interests: "Interesses",
        lastName: "Achternaam",
        latestTransaction: "Laatste transactie",
        less: "Minder",
        more: "Meer",
        mostCommonBrands: "Meest voorkomende merk(en)",
        name: "Naam",
        newsletterSubscription: "Nieuwsbrief inschrijvingen",
        nextTenResults: "Volgende 10 resultaten",
        no: "Nee",
        noAddress: "Geen adres",
        noAddressesFound: "Geen adres(sen) gevonden.",
        noBrandsFound: "Geen merken gevonden",
        originalSellingPrice: "Originele verkoopprijs",
        otherAddresses: "Andere adressen",
        phoneNumber: "Telefoonnummer",
        postalCode: "Postcode",
        postalCodeHouseNumber: "Postcode + Huisnummer",
        priceAscending: "Prijs oplopend",
        priceDescending: "Prijs aflopend",
        removeAll: "Verwijder alles",
        results: "Resultaten",
        rfmGroup: "RFM groep",
        salutationFemale: "Mevr.",
        salutationMale: "Dhr.",
        searchBy: "Zoek op",
        searchCustomer: "Klant zoeken",
        searchCustomers: "Klant zoeken",
        searchTerm: "Zoekterm",
        securityCode: "Veiligheidscode",
        selectedFilters: "Geselecteerde filter(s)",
        sellDate: "Verkoopdatum",
        sellDateNewestToOldest: "Verkoopdatum (nieuw - oud)",
        sellDateOldestToNewest: "Verkoopdatum (oud - nieuw)",
        size: "Maat",
        sortBy: "Sorteer op",
        targetGroups: "Doelgroepen",
        totalAmount: "Totaal bedrag",
        totalPurchases: "Totaal aankopen",
        totalRetours: "Totaal retouren",
        transactions: "Transacties",
        transactionsPastTwelveMonths: "Transacties afg. 12 maanden",
        unknown: "Onbekend",
        unlock: "Ontgrendelen",
        view: "Bekijken",
        viewXAmountProducts: (amount) => `${amount} product(en) bekijken`,
        webshop: "Webshop",
        whereBought: "Waar verkocht",
        xAmountAddresses: (amount) => `Adressen (${amount})`,
        xAmountProducts: (amount) => `${amount} product(en)`,
        xCustomersFound: (amount) => `${amount} Klant(en) gevonden`,
        yes: "Ja"
    },
    messages: {
        error: {},
        info: {
            customerCouldNotBeFound: "Deze klant kon niet gevonden worden, klik op de onderstaande knop om terug te gaan naar het overzicht.",
            customerNotFound: "Klant niet gevonden"
        },
        loading: {},
        validation: {
            fieldRequired: "Dit veld is verplicht.",
            invalidPostalCode: "Deze postcode is ongeldig.",
            securityCodeInvalid: "De veiligheidscode is incorrect, probeer het alstublieft nog een keer."
        },
        success: {},
        warning: {}
    }
};

export default nlNl;
