const env = process.env;

export const appName = "Shopper Insights";
export const appPrefix = "si";

const appConfig = {
    name: appName,
    prefix: appPrefix,
    version: env.REACT_APP_VERSION,
    cacheName: `${appPrefix}_cache_v${env.REACT_APP_VERSION}`,
    apis: {
        ois: {
            bulk: {
                v1: env.REACT_APP_APIS_OIS_BULK_V1,
            },
            core: {
                v1: env.REACT_APP_APIS_OIS_CORE_V1,
                v2: env.REACT_APP_APIS_OIS_CORE_V2,
            },
            circulation: {
                v1: env.REACT_APP_APIS_OIS_CIRCULATION_V1,
            },
            configuration: {
                v1: env.REACT_APP_APIS_OIS_CONFIGURATION_V1,
            },
            postal: {
                v1: env.REACT_APP_APIS_OIS_POSTAL_V1,
            },
            productComplaints: {
                v1: env.REACT_APP_APIS_OIS_PRODUCTCOMPLAINTS_V1,
            },
            userManagement: {
                v1: env.REACT_APP_APIS_OIS_USERMANAGEMENT_V1,
            },
            authentication: {
                v1: env.REACT_APP_APIS_OIS_AUTHENTICATION_V1,
            },
        },
        tritonX: {
            v18: env.REACT_APP_APIS_TRITONX_V18,
        },
    },
    ekFashion: {
        imageBaseUrl: env.REACT_APP_EKFASHION_IMAGE_BASE_URL,
    },
    telemetry: {
        appInsights: {
            enabled: env.NODE_ENV === "production",
            instrumentationKey: env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
        },
    },
};

export default appConfig;
